import React, {useContext} from 'react'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import styles from './Projects.module.scss'
import { getImageObj } from '../../misc/utilities'
import { Card, BackgroundWrapper, DotGrid, DribbblePreview, Slider } from '../../components/'
import {LanguageStrings} from '../../Context'
import miro from '../../images/miro-1.svg'
import linaFront from '../../images/lina-forsida.svg'

const Projects = ({ projects, page, location, dribbble }) => {
  const texts = useContext(LanguageStrings)
  return (
    <div className={styles.container}>
      <Helmet>
        <meta property='og:title' content={page.yoast_title} />
        <meta property='og:description' content='' />
        <meta property='og:type' content='website' />
        <meta property='og:image' content='' />
        <meta property='og:url' content={location.href} />
      </Helmet>
      <BackgroundWrapper
        elements={[
          {
            top: '200px',
            left: '-500px',
            component: <img src={linaFront} alt='linaFront' />
          }
        ]}
      >
        <div className='container pt-6'>
          <BackgroundWrapper
            elements={[
              {
                top: '920px',
                right: '20%',
                offsetY: '120%',
                component: <img src={miro} alt='miro' />
              }, {
                top: '50px',
                right: '-53px',
                component: <DotGrid volumeX={19} volumeY={9} />
              }
            ]}
          >
            <div className='row'>
              <div className='col-6'>
                <h1 className='mb-4'>{ texts.projects }</h1>
              </div>
            </div>
            <ProjectsSection projects={projects} />
          </BackgroundWrapper>
        </div>
        <section className='container pb-3'>
          <h2>{ texts.dribble_title }</h2>
        </section>
        <section className='pb-xl'>
          <Slider
            allowTouchMove={false}
            grabCursor={false}
            slidesPerView={4}
            breakpoints={{
              768: {
                slidesPerView: 1.5,
                allowTouchMove: true,
                grabCursor: true
              }
            }}>
            {dribbble.map((shot, index) => (
              <DribbblePreview
                key={index}
                className='swiper-slide'
                image={shot.cover}
                title={shot.title}
                link={shot.url}
              />
            ))}
          </Slider>
        </section>
      </BackgroundWrapper>
    </div>
  )
}

export default Projects

const ProjectsSection = ({ projects }) => (
  <section className='row pb-xl'>
    {projects && projects.map((project, index) => (
      <div
        key={index}
        className='col-6 col-md-4 mb-4'
      >
        <Card
          image={getImageObj(get(project, 'acf.image.sizes', {}), 'card')}
          title={project.title.rendered}
          bordered
          linkTo={`/verkefnin/${project.slug}`}
        />
      </div>
    ))}
  </section>
)
