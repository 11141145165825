import React, { Component } from 'react'
import Cmp from './Projects'
import { StaticQuery, graphql } from 'gatsby'
import { isThisSecond } from 'date-fns';

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`{
          wordpressPage(wordpress_id: {eq: 499}) {
            yoast_title
          }
          allDribleProjects {
            edges {
              node {
                title
                description
                id
                published
                updated
                url
                tags
                cover
                width
                height
              }
            }
          }
        }`}

        render={data => (
          <Cmp
            dribbble={data.allDribleProjects.edges.map(edge => edge.node).slice(0, 4)}
            page={data.wordpressPage}
            projects={this.props.pageContext.projects}
            {...this.props}
          />
        )}
      />
    )
  }
}

export default Connected
